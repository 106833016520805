import React, { Component } from "react";
import "./Intro.css";

class Intro extends Component {
  render() {
    return (
      <div className="intro">
        <h1>
          <b>Hello, I'm Agung</b>
        </h1>
        <p>
            I'm a Tech-Evangelist, Product Enthusiast, and Entrepreneur wanna be. 
            This website is a journal of the things I have learned over the past few years.
            Feel free to reach out at <b>hello</b> at <b>agungprasetio.id</b>.
        </p>
      </div>
    );
  }
}

export default Intro;
